import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import cloudsecurity from '../images/services/new/pragicts-cloudops-security.jpeg'
import infrastructure from '../images/services/new/pragicts-cloudops-infrastructure.jpg'
import iot from '../images/services/new/pragicts-cloudops-iot.jpg'
import saas from '../images/services/new/pragicts-cloudops-sase.jpg'

const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | CloudOps | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={cloudsecurity}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Cloud Security</h3>
                                                <p>Cloud security, also known as cloud computing security, is a collection of security measures designed to protect cloud-based infrastructure, applications, and data. Organizations will inevitably have more than a single vendor cloud strategy to ensure that the best cloud technology services and platforms are brought together for business needs. These mandate an effective cloud security strategy that is cohesive and comprehensive to ensure total and consistent security. </p>
                                                <p>PragICTS provides cloud security services for the effective management of cloud security across the entire IT landscape.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                            
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={infrastructure}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Cloud Infrastructure Management </h3>
                                                <p>Cloud management is the organized oversight, control, administration, and maintenance of public cloud, private cloud, or more commonly, hybrid (public and private) multi-cloud computing infrastructure, services, and resources. It gives IT teams a firm hold over scalable and dynamic cloud computing environments. End-to-end design, provisioning, management, and support of cloud infrastructure. </p>
                                                <p>PragICTS provides cloud security services for the effective management of cloud security across the entire IT landscape.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                            
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={iot}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Internet of Things (IoT)</h3>
                                                <p>The Internet of Things (IoT) describes devices with sensors, processing ability, software, and other technologies that connect and exchange data with other devices and systems over the Internet or other communications networks. The Internet of Things encompasses electronics, communication, and computer science engineering. Internet of Things has been considered a misnomer because devices do not need to be connected to the public internet, they only need to be connected to a network and be individually addressable. </p>
                                                <p>PragICTS provides IoT-related services to enable clients to gather and action data and information on a real-time basis from sources that were an impossibility. These services are complemented by the Sentinel platform (https://sentinel.pragicts.com) which provides for centralized monitoring of the entire business landscape from any location.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={saas}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>SASE (Secure Assess Service Edge) and SSE (Secure Service Edge)</h3>
                                                <p>SASE / SSE provides a cloud-centric/native approach to networking, security, and performance. PragICTS provides design, implementation, transformation, management, and support services for SASE (Secure Assess Service Edge) and SSE (Secure Service Edge). At the micro level, the following SASE and SSE services are catered for</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Secure Connectivity</h3>
                                                <p>SASE/SSE integrates secure access technologies such as VPN, SD-WAN (Software-Defined Wide Area Network), and zero-trust network access (ZTNA) to provide secure and optimized network connections for users, devices, and branch offices, regardless of their location.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>


                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Network Security</h3>
                                                <p>SASE/SSE combines multiple security functions, including firewall-as-a-service (FWaaS), secure web gateways (SWG), data loss prevention (DLP), and more into a unified security platform. This consolidates security capabilities and simplifies management.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Identity-Centric Security</h3>
                                                <p>Access and security policies in SASE/SSE are often based on user and device identities, implementing zero-trust principles. Identity and access management (IAM) play a crucial role in controlling access.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Zero Trust</h3>
                                                <p>SASE/SSE embraces the Zero Trust model, where trust is never assumed, and access decisions are made based on continuous verification and contextual factors.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Content and Threat Inspection</h3>
                                                <p>SASE/SSE services include content filtering and threat detection capabilities to protect against malware, phishing, and other cyber threats.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Simplified Management</h3>
                                                <p>Centralized management consoles provide administrators with a holistic view of network security and connectivity. This reduces complexity and enhances control.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>User Experience Optimization</h3>
                                                <p>SASE/SSE services may include features for optimizing the performance and user experience of cloud applications by using techniques like direct-to-cloud routing.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3>Global Reach</h3>
                                                <p>SASE/SSE providers often have a distributed network of points of presence (PoPs) around the world, allowing organizations to provide secure, low-latency access to cloud resources and branch offices globally.</p>
                                   
                                                
                                                <div className="clearfix"></div>
                                           
                                            
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
